<template>
  <div
    v-if="product.ProductApiType === ProductApiType.Incorrect"
    class="v-menu-card v-error-color"
    v-html="translate('productInListPage.incorrect')"
  />
  <div
    v-else
    class="v-menu-card"
    itemscope
    data-test-id="menu-card"
    itemtype="http://schema.org/Product"
  >
    <menu-product-meta-data :product-base="product" />
    <menu-product-in-list-product-card
      v-if="product.ProductApiType === ProductApiType.Default"
      :card-click="cardClick"
      :disable-lazy="disableLazy"
      :product-in-list="product"
    />
    <menu-product-in-list-slots-lunch-card
      v-else-if="product.ProductApiType === ProductApiType.Lunch"
      :card-click="cardClick"
      :disable-lazy="disableLazy"
      :product-in-list="product"
    />
    <menu-product-in-list-slots-half-card
      v-else-if="product.ProductApiType === ProductApiType.Half"
      :card-click="cardClick"
      :disable-lazy="disableLazy"
      :product-in-list="product"
    />
    <menu-product-in-list-slots-quarter-card
      v-else-if="product.ProductApiType === ProductApiType.Quarter"
      :card-click="cardClick"
      :disable-lazy="disableLazy"
      :product-in-list="product"
    />
    <template v-else-if="product.ProductApiType === ProductApiType.Combine">
      <!--TODO: combo-->
    </template>
    <menu-product-in-list-constructor-card
      v-else-if="product.ProductApiType === ProductApiType.Constructor"
      :card-click="cardClick"
      :disable-lazy="disableLazy"
      :product-in-list="product"
    />
    <div
      v-else-if="product.ProductApiType === ProductApiType.BigConstructor"
      class="v-menu-card v-error-color"
    >
      <!--in case we actually going to put big constructor in menu? -->
      {{ translate('productInListPage.incorrect') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Option, ProductInList } from '~types/menuStore'

import { ProductApiType } from '~api/consts'

const { disableLazy = false, product } = defineProps<{
  product: ProductInList
  disableLazy?: boolean
}>()

const menuStore = useMenuStore()
const { translate } = useI18nSanitized()
const { openProductPopup } = useProduct()

async function cardClick(): Promise<void> {
  const options = ([] as Option[]).concat.apply([], Object.values(product.Options))

  if (
    menuStore.StopListProductIds.has(product.ID) ||
    (options.length > 0 && options.every((option) => menuStore.StopListOptionIds.has(option.ID)))
  )
    return

  return await openProductPopup(product)
}
</script>

<style lang="scss">
@use 'assets/variables';

.v-menu-card {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: variables.$BorderRadius;

  &.v-error-color {
    font-size: 1rem;
    padding: 2rem;
    text-align: center;
  }
}
</style>
