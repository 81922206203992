<template>
  <div
    :class="`v-stickers-pack v-stickers-pack--${appConfig.VueSettingsPreRun.MenuItemStickerBehavior}`"
    :style="`--stickerSize: ${4 * (13 - appConfig.VueSettingsPreRun.MenuItemsInRow)}px`"
  >
    <common-popover
      v-for="(sticker, index) in stickersSorted"
      :key="`sticker-${index}`"
      class="v-stickers-item"
      :delay="200"
    >
      <template #hoverable>
        <span
          class="v-sticker"
          :class="appConfig.VueSettingsPreRun.MenuStickersShowInGroups ? 'v-pointer' : ''"
          :style="`background-image: ${sticker.ImageUrl ? `url(${sticker.ImageUrl})` : 'none'};`"
          @click.stop="(error) => onClick(error, sticker.ID)"
        />
        <span
          v-if="appConfig.VueSettingsPreRun.MenuStickersEnableShadow"
          class="v-sticker-shadow"
          :style="`background-image: ${sticker.ImageUrl ? `url(${sticker.ImageUrl})` : 'none'};`"
        />
      </template>

      <template #content>
        <span v-html="sticker.Title" />
      </template>
    </common-popover>
  </div>
</template>

<script setup lang="ts">
import type { StickerTag } from '~types/menuStore'

import type { GUID } from '@arora/common'

const { stickers } = defineProps<{
  stickers: StickerTag[]
}>()

const menuStore = useMenuStore()

const appConfig = useAppConfig()
const stickersSorted = computed<StickerTag[]>(() =>
  [...stickers]
    .sort((a: StickerTag, b: StickerTag) => {
      return a.Title > b.Title ? 1 : -1 //sort alphabetically
    })
    .filter((sticker: StickerTag) => sticker.ImageUrl)
)

function onClick(_event: MouseEvent, stickerId: GUID): void {
  if (appConfig.VueSettingsPreRun.MenuStickersShowInGroups) {
    menuStore.toggleFilterSticker(stickerId)
  }
}
</script>

<style lang="scss">
@use 'assets/mixins';

$stickerSize: var(--stickerSize, 32px);

.v-stickers-pack {
  position: absolute;
  top: 16px;
  left: 16px;
  display: flex;
  z-index: 2;
  @include mixins.trans;

  &--row {
    gap: 4px;

    .v-stickers-item {
      width: $stickerSize;
      height: $stickerSize;
    }
  }

  &--stack {
    .v-stickers-item {
      @include mixins.trans;
      width: calc($stickerSize / 3);
      height: $stickerSize;
    }

    &:hover {
      gap: 10px;

      .v-stickers-item {
        width: $stickerSize;
        height: $stickerSize;
      }
    }
  }
}

.v-sticker {
  background-repeat: no-repeat no-repeat;
  background-size: $stickerSize;
  display: block;
  width: $stickerSize;
  height: $stickerSize;
  transform: scale(1);
  border-radius: 50%;
  @include mixins.trans;

  &:hover {
    transform: scale(1.1);
  }
}

.v-sticker-shadow {
  width: $stickerSize;
  height: $stickerSize;
  display: block;
  position: absolute;
  opacity: 0.4;
  top: 3px;
  filter: blur(14px);
  z-index: -1;
  background-repeat: no-repeat no-repeat;
  background-size: $stickerSize;
  border-radius: 50%;
}
</style>
